import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import BaseContainer from '../components/basePage/BaseContainer';

const BasePage = () => {
    const navigate = useNavigate();

    return (
        <Stack alignItems="center" sx={{flex: 1, height: '100vh'}}>
            <Header bg borderBottom>
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        position: 'relative',
                        paddingX: 2,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h5" fontWeight="600" color="#ccffff" ml={2}>
                        Base Explorer
                    </Typography>
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'right', flex: 1}}>
                        <Button variant="outlined" onClick={() => navigate('/')} sx={{mr: 2}}>
                            <Typography variant="button" color="#ccffff">
                                Home
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Header>
            <BaseContainer/>
        </Stack>
    );
};

export default BasePage;
