import { Keypair } from "@solana/web3.js";
import bs58 from 'bs58';
import CryptoJS from 'crypto-js';
import { ChainIds, CurrentConfig } from "./constants";
import { ethers } from 'ethers';

export function formatDateTime(dateTimeString: string, withSeconds = false): string {
    const date = new Date(dateTimeString);
    // const year = date.getFullYear().toString().slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    if(withSeconds){
        const seconds = ('0' + date.getSeconds()).slice(-2);
        return `${month}/${day} ${hours}:${minutes}:${seconds}`;
    }else{
        return `${month}/${day} ${hours}:${minutes}`;
    }
}

export function shortenAddress(address: string, length = 6) {
    try{
        return address.slice(0, length) + '...' + address.slice(-length);
    }catch(e){
        return '';
    }
}

export function generateRandomString(length: number) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function displayWithNonZeroDecimals(value: number | string | undefined, decimalsAfterNonZero = 2): string | undefined {
    if (value === undefined || value === null) {
        return undefined;
    }
    const numStr = value.toString();
    const [integerPart, decimalPart] = numStr.split('.');

    if (!decimalPart) {
        // No decimal part
        return numStr;
    }

    // Find the position of the first non-zero digit in the decimal part
    let firstNonZeroIndex = -1;
    for (let i = 0; i < decimalPart.length; i++) {
        if (decimalPart[i] !== '0') {
            firstNonZeroIndex = i;
            break;
        }
    }

    if (firstNonZeroIndex === -1) {
        // All decimals are zero
        return integerPart;
    }

    // Calculate the length to display
    const endIndex = firstNonZeroIndex + decimalsAfterNonZero + 1; // +1 to include the first non-zero
    const truncatedDecimalPart = decimalPart.slice(0, endIndex);
    const significantDecimalPart = truncatedDecimalPart.replace(/0+$/, '');

    // Combine the integer and truncated decimal parts
    return `${integerPart}.${significantDecimalPart}`;
}

const passphrase = 'forencryptkey';

export function encryptKey(key: string | undefined){
    if(key){
        return CryptoJS.AES.encrypt(key, passphrase).toString();
    }else{
        return '';
    }
}

export function decryptKey(encrypted: string | undefined){
    if(encrypted){
        return CryptoJS.AES.decrypt(encrypted, passphrase).toString(CryptoJS.enc.Utf8);
    }else{
        return '';
    }
}

export function createKeypairFromString(base58PrivateKey : string): Keypair | null {
    try {
        const secretKey = bs58.decode(base58PrivateKey);

        // Ensure the length of the secretKey is 64 bytes
        if (secretKey.length !== 64) {
            throw new Error('Invalid secret key length. Expected 64 bytes.');
        }

        const keypair = Keypair.fromSecretKey(secretKey);
        return keypair;

    } catch (error) {
        console.error("Failed to create Keypair:");
        return null;
    }
}

export function createWallet(privateKey: string, chainId: ChainIds): ethers.Wallet | null{
    try{
        const provider = new ethers.providers.JsonRpcProvider(CurrentConfig.rpc[chainId]);
        const wallet = new ethers.Wallet(privateKey, provider);
        return wallet;
    }catch(e){
        return null;
    }
}