import React, { useEffect, useState } from 'react';
import { Box, InputLabel, FormControl, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { getBaseTx } from '../../api/bot.api';
import { toast } from 'react-toastify';
import { displayWithNonZeroDecimals, formatDateTime } from '../../libs/utils';
import { LoadingButton } from '@mui/lab';
import { LineChart } from '@mui/x-charts';

const defaultParams = { address: '', txCount: 10, token: 'USDC' };
const tokenList = ['USDC', 'cbBTC', 'AERO'];

interface BaseTxInterface {
    timestamp: number;
    hash: string;
    type: 'in' | 'out';
    token: string;
    amount: string;
    beforeBalance: string;
    afterBalance: string;
}

interface BaseParams {
    address: string;
    txCount: number;
    token: string;
}

export default function BaseContainer() {
    const [params, setParams] = useState(defaultParams);
    const [baseTx, setBaseTx] = useState<BaseTxInterface[]>([]);
    const [currentBalance, setCurrentBalance] = useState<string>('');
    const [isApplying, setIsApplying] = useState<boolean>(false);

    const [xLabels, setXLabels] = useState<string[]>([]);
    const [balanceData, setBalanceData] = useState<number[]>([]);

    const handleParamChange = (name: keyof BaseParams, value: string) => {
        const temp = {...params};
        if(name === 'txCount' && !isNaN(Number(value))){
            temp.txCount = Number(value);
        }else{
            (temp as any)[name] = value;
        }
        setParams(temp);
    }

    const handleApply = async () => {
        if(!params.address || !params.txCount){
            return;
        }
        setIsApplying(true);
        const res = await getBaseTx(params);
        if(res.success){
            setBaseTx(res.data.transactions);
            setCurrentBalance(res.data.currentBalance);
        }else{
            toast.error(`Error on getting base tx - ${res.msg}`);
        }
        setIsApplying(false);
    }

    useEffect(()=>{
        if(baseTx.length > 0){
            const temp = [...baseTx];
            temp.reverse();
            setXLabels(temp.map((tx)=>formatDateTime(new Date(tx.timestamp * 1000).toLocaleString(), true)));
            setBalanceData(temp.map((tx)=>Number(tx.afterBalance)));
        }
    }, [baseTx]);

    return (
        <Stack sx={{width: '100%', flex: 1}}>
            <Box sx={{display: 'flex', pt: 4, pb: 2, px: 6, gap: 2, alignItems: 'center'}}>
                <TextField size="small" label="Base Address" sx={{width: '50%', maxWidth: '600px'}} value={params.address} onChange={(e) => handleParamChange("address", e.target.value)}/>
                <TextField size="small" label="Tx Count" sx={{maxWidth: '200px'}} value={params.txCount} onChange={(e)=>handleParamChange("txCount", e.target.value)}/>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel>Select Token</InputLabel>
                    <Select
                        value={params.token}
                        onChange={(e)=>handleParamChange("token", e.target.value)}
                        label="Select Token"
                        >
                        {tokenList.map((token) => (
                            <MenuItem key={token} value={token}>
                                {token}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <LoadingButton sx={{width: '120px'}} loading={isApplying} loadingIndicator="Applying..." variant="outlined" onClick={handleApply}>
                            Apply
                </LoadingButton>
            </Box>
            {baseTx.length > 0 &&
            <>
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Typography fontWeight="600" color="#ccffff" ml={2}>
                        Current Balance: {displayWithNonZeroDecimals(currentBalance, 1)}
                    </Typography>
                </Box>
                <Box sx={{width: '100%', height: '50%', maxHeight: '550px', overflowY: 'auto', flex: 1, display: 'flex', justifyContent: 'center', px: 4}}>
                    <LineChart
                        series={[{ data: balanceData, area: false, showMark: false }]}
                        xAxis={[{ scaleType: 'point', data: xLabels }]}
                        sx={{
                            '.MuiLineElement-root': {
                                // display: 'none',
                            },
                        }}
                    />
                </Box>
                <Box sx={{width: '100%', px: 2, pt: 2, pb: 4}}>
                    <TextField size="small" sx={{width: '100%'}}/>
                </Box>

                <Box sx={{width: '100%', flex: 1, overflowY: 'auto',
                    '& table': {
                        width: '100%',
                        border: '1px solid blue',
                        borderColor: 'divider',
                        borderRadius: '4px 4px 0 0',
                        borderBottom: 'none',
                        thead: {
                            height: '50px',
                            position: 'sticky',
                            top: 0,
                            backgroundColor: '#1a1a1a',
                            borderRadius: '12px',
                            zIndex: 1,
                        },
                        th: {
                            color: 'text.disabled',  
                        },
                        tr: {
                            height: '50px',
                        },
                        td: {
                            textAlign: 'center',
                            borderBottom: '1px solid blue',
                            borderBottomColor: 'divider',
                            
                        }
                    },
                }}>
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th>Before Balance</th>
                                <th>IN/OUT</th>
                                <th>Token</th>
                                <th>Amount</th>
                                <th>After Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {baseTx.map((tx, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{formatDateTime(new Date(tx.timestamp * 1000).toLocaleString())}</td>
                                    <td>{displayWithNonZeroDecimals(tx.beforeBalance, 1)}</td>
                                    <td>{tx.type}</td>
                                    <td>{tx.token}</td>
                                    <td>{displayWithNonZeroDecimals(tx.amount, 1)}</td>
                                    <td>{displayWithNonZeroDecimals(tx.afterBalance, 1)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Box>
            </>}
        </Stack>
    );
}