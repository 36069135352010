import React, { useEffect, useState } from 'react';

import { Box, Button, Checkbox, FormControl, InputLabel, MenuItem, Select, Stack, ToggleButton, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { getHistories, getLog, setHistoryAction } from '../../api/bot.api';
import { displayWithNonZeroDecimals, formatDateTime, shortenAddress } from '../../libs/utils';
import { PositionSettingsInterface } from '../homepage/types';
import LogModal from './LogModal';

export interface HistoryInterface {
    _id: string;
    commandType: string;
    data: {
        positionMint: string;
        oldPositionMint?: string;
        feesToRebalance: boolean;
        symbolA: string;
        symbolB: string;
        totalYield?: number;
        balance?: number;
        afterPositionBalance?: number;
        wastedFee?: number;
        wastedFeeUsd?: number;
        converted?: number;
        walletAddress: string;
        poolBalance: number;
        afterPoolBalance: number;
        walletBalance: number;
        afterWalletBalance?: number;
        beforeTotalBalance?: number;
        afterTotalBalance?: number;
        depositAmountA?: number;
        depositAmountB?: number;
        realDepositPercent?: number;
        difference?: number;
        swapMinus?: number;
        swapMinusJup?: number;
        prices?: { [key: string]: string };
        afterPrices?: { [key: string]: string };
        rebalance?: { [key: string]: string };
        settings?: PositionSettingsInterface;
    },
    status: string;
    createdAt: string;
}

const commandList = ['collect', 'rebalance', 'close', 'stop', 'preserve', 'preserve-rebalance'];
const statusList = ['doing', 'done', 'failed'];
const textColor: { [key: string]: string } = {
    'doing': 'error',
    'done': 'info',
    'failed': 'secondary'
}
const skipList = [ 0, 100, 200, 400, 800, 1000, 1500, 2000, 2500, 3000];
const countList = [ 100, 200, 400, 800];

export default function HistoryContainer() {
    const [histories, setHistories] = React.useState<HistoryInterface[]>([]);
    const [filteredHistories, setFilteredHistories] = React.useState<HistoryInterface[]>([]);
    const [skipCount, setSkipCount] = useState(0);
    const [selectedCount, setSelectedCount] = useState(100);
    const [checkedList, setCheckedList] = useState<string[]>([]);
    const [commands, setCommands] = useState<string[]>(['rebalance']);
    const [statuses, setStatuses] = useState<string[]>(['done', 'doing']);
    const [logModalOpen, setLogModalOpen] = useState<boolean>(false);
    const [logData, setLogData] = useState<string>('');
    const [modalCaption, setModalCaption] = useState<string>('');
    const [converted, setConverted] = useState<number>(0);
    const [ctrled, setCtrled] = useState(false);
    const [selectedPool, setSelectedPool] = useState<string>("all");
    const [poolList, setPoolList] = useState<string[]>(['all']);

    const updateHistories = async (skipCount: number, count: number) => {
        const res = await getHistories({skipCount, count});
        if(res.success){
            setHistories(res.data);
            // Calculate total converted value
            const totalConverted = res.data.reduce((sum: number, history: HistoryInterface) => {
                const convertedValue = history.data?.converted ? history.data.converted : 0;
                return sum + convertedValue;
            }, 0);

            // Set the total converted value to state
            setConverted(totalConverted);
        }
    }

    const handleAllChecked = (checked: boolean) => {
        if(checked){
            const allIds = histories
                .filter(history => commands.includes(history.commandType) && statuses.includes(history.status))
                .map(history => history._id);
            setCheckedList(allIds);
        }else{
            setCheckedList([]);
        }
    };

    const handleChecked = (_id: string, checked: boolean) => {
        if(checked){
            setCheckedList([...checkedList, _id]);
        }else{
            setCheckedList(checkedList.filter(id => id !== _id));
        }
    };

    const handleAction = async ( action: string, _id: string) => {
        const res = await setHistoryAction({ action, ids: [_id]});
        if(res.success){
            toast.success(`Done action: ${action}`);
            updateHistories(skipCount, selectedCount);
        }else{
            toast.error(res.msg);
        }
    }

    const handleViewLog = async ( _id: string, mint: string) => {
        const res = await getLog({ _id });
        if(res.success){
            setLogData(res.data);
            setLogModalOpen(true);
            setModalCaption(mint);
        }else{
            toast.error(res.msg);
        }
    }

    const handleDeleteSelected = async () => {
        const res = await setHistoryAction({ action: 'delete', ids: checkedList});
        if(res.success){
            toast.success("successfully deleted checked items");
            updateHistories(skipCount, selectedCount);
        }else{
            toast.error(res.msg);
        }
    }
    const handleCommandChange = (value: string) => {
        if (commands.includes(value)) {
            setCommands(commands.filter(command => command !== value));
        } else {
            setCommands([...commands, value]);
        }
    }

    const handleStatusChange = (value: string) => {
        if (statuses.includes(value)) {
            setStatuses(statuses.filter(status => status !== value));
        } else {
            setStatuses([...statuses, value]);
        }
    }
    
    useEffect(() => {
        updateHistories(skipCount, selectedCount);
        const timer = setInterval(() => {
            updateHistories(skipCount, selectedCount);
        }, 5000)
        return () => {
            clearInterval(timer);
        }
    }, [selectedCount, skipCount])

    useEffect(() => {
        // Add keyboard event listeners
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Control') {
                setCtrled(true);
            }
        };

        const handleKeyUp = (e: KeyboardEvent) => {
            if (e.key === 'Control') {
                setCtrled(false);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        // Cleanup listeners
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    useEffect(() => {
        // Get unique wallet addresses from histories
        const uniquePools = Array.from(new Set(histories.map(history => `${history.data?.symbolA}/${history.data?.symbolB}`)))
            .filter(address => address !== undefined) as string[];
        
        setPoolList(uniquePools);
        // Calculate total profit for the selected address or all addresses
        const filteredHistories = histories.filter(history => {
            const poolMatch = selectedPool === 'all' || `${history.data?.symbolA}/${history.data?.symbolB}` === selectedPool;
            const commandMatch = commands.includes(history.commandType);
            const statusMatch = statuses.includes(history.status);
            return poolMatch && commandMatch && statusMatch;
        });

        setFilteredHistories(filteredHistories);

    }, [histories, selectedPool, commands, statuses])

    return (
        <Box pt={2} sx={{width: '100%', flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
            <LogModal open={logModalOpen} logData={logData} onClose={()=>setLogModalOpen(false)} caption={modalCaption}/>
            <Box display='flex' justifyContent={'space-between'} px={4}>
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} gap={1}>
                    {commandList.map((cmd) => (
                        <ToggleButton key={cmd} sx={{padding: '4px 12px', color: 'divider'}} value={cmd} selected={commands.includes(cmd)} onChange={() => handleCommandChange(cmd)}>
                            <Typography>{cmd}</Typography>
                        </ToggleButton>
                    ))}
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel>Select Pool</InputLabel>
                        <Select
                            value={selectedPool}
                            onChange={(e)=>setSelectedPool(e.target.value)}
                            label="Select Pool"
                            >
                            <MenuItem value="all">All</MenuItem>
                            {poolList.map((pool) => (
                                <MenuItem key={pool} value={pool}>
                                    {pool}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel>Skip Count</InputLabel>
                        <Select
                            value={skipCount}
                            onChange={(e)=>setSkipCount(Number(e.target.value))}
                            label="Skip Count"
                            >
                            {skipList.map((cvalue) => (
                                <MenuItem key={cvalue} value={cvalue}>
                                    {cvalue}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel>Select Count</InputLabel>
                        <Select
                            value={selectedCount}
                            onChange={(e)=>setSelectedCount(Number(e.target.value))}
                            label="Select Count"
                            >
                            {countList.map((cvalue) => (
                                <MenuItem key={cvalue} value={cvalue}>
                                    {cvalue}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                </Box>
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Button variant='outlined' color='secondary' onClick={handleDeleteSelected}>Delete Selected</Button>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} gap={1}>
                    {statusList.map((cmd) => (
                        <ToggleButton key={cmd} sx={{padding: '4px 12px', color: 'divider'}} value={cmd} selected={statuses.includes(cmd)} onChange={() => handleStatusChange(cmd)}>
                            <Typography>{cmd}</Typography>
                        </ToggleButton>
                    ))}
                </Box>
            </Box>
            <Box mt={1} pb={1} sx={{display: 'flex', flexDirection: 'column', width: '100%', overflowY: 'auto', flex: 1,
                '& table': {
                    border: '1px solid blue',
                    borderColor: 'divider',
                    borderRadius: '12px 12px 0 0',
                    borderBottom: 'none',
                    thead: {
                        height: '50px',
                        position: 'sticky',
                        top: 0,
                        backgroundColor: '#1a1a1a',
                        borderRadius: '12px',
                        zIndex: 1,
                    },
                    th: {
                        color: 'text.disabled',  
                    },
                    tr: {
                        height: '50px',
                    },
                    td: {
                        textAlign: 'center',
                        borderBottom: '1px solid blue',
                        borderBottomColor: 'divider',
                        
                    }
                },
             }}>
                <table>
                    <thead>
                        <tr>
                            <th><Checkbox onChange={(e)=>handleAllChecked(e.target.checked)}/></th>
                            <th>Date</th>
                            <th>cmd</th>
                            <th>Pool</th>
                            <th>NFT ID</th>
                            <th>Balance</th>
                            <th>All Pools</th>
                            <th>Wallet</th>
                            <th>Total
                            </th>
                            <th>After Rebal</th>
                            <th>Plus</th>
                            <th>USDT</th>
                            <th>Fee</th>
                            <th>Swap Minus</th>
                            <th>Deposit %</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredHistories.map((info) => (
                                    <tr key={info._id}>
                                        <td>
                                            <Checkbox checked={checkedList.includes(info._id)} onChange={(e)=>handleChecked(info._id, e.target.checked)}/>
                                        </td>
                                        <td>{formatDateTime(info.createdAt)}</td>
                                        <td>{info.commandType}</td>
                                        <td style={{maxWidth: '100px'}}>
                                            <Stack>
                                                <Typography>{info.data.symbolA}/{info.data.symbolB}</Typography>
                                                {info.data.prices &&
                                                    <Typography variant="caption" color='divider'>
                                                        {displayWithNonZeroDecimals(info.data.prices[info.data.symbolA], 2) || '_'} / {displayWithNonZeroDecimals(info.data.prices[info.data.symbolB], 2) || '_'}
                                                    </Typography>
                                                }
                                            </Stack>
                                        </td>
                                        <td style={{maxWidth: '100px'}}>
                                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                                <Typography>{shortenAddress(info.data.positionMint, 4)}</Typography>
                                                {info.data.oldPositionMint && <Typography variant="caption" color='divider'>{shortenAddress(info.data.oldPositionMint, 4)}</Typography>}
                                            </Box>
                                        </td>
                                        <td>
                                            <Stack>
                                                <Typography>${displayWithNonZeroDecimals(info.data.balance, 1)}</Typography>
                                                <Typography variant="caption" color='divider'>{displayWithNonZeroDecimals(info.data.afterPositionBalance, 1)}</Typography>
                                            </Stack>
                                        </td>
                                        <td>${displayWithNonZeroDecimals(info.data.poolBalance, 1)}</td>
                                        <td>${displayWithNonZeroDecimals(info.data.walletBalance, 1)}</td>
                                        <td>
                                            <Stack>
                                                <Typography>${displayWithNonZeroDecimals(info.data.beforeTotalBalance, 1)}</Typography>
                                            </Stack>
                                        </td>
                                        <td>
                                            <Stack>
                                                <Typography>${displayWithNonZeroDecimals(info.data.afterTotalBalance, 1)}</Typography>
                                                {info.data.afterPrices &&
                                                    <Typography variant="caption" color='divider'>
                                                        {displayWithNonZeroDecimals(info.data.afterPrices[info.data.symbolA], 2) || '_'} / {displayWithNonZeroDecimals(info.data.afterPrices[info.data.symbolB], 2) || '_'}
                                                    </Typography>
                                                }
                                            </Stack>
                                        </td>
                                        <td>{displayWithNonZeroDecimals(info.data.difference, 1)}</td>

                                        
                                        <td>
                                            <Stack>
                                                <Typography>{displayWithNonZeroDecimals(info.data.converted, 1)}</Typography>
                                                <Typography variant="caption" color='divider'>{displayWithNonZeroDecimals(info.data.totalYield, 1)}</Typography>
                                            </Stack>
                                        </td>
                                        <td>
                                            {info.data.wastedFeeUsd ? `$${displayWithNonZeroDecimals(info.data.wastedFeeUsd, 1)}` : ''}
                                        </td>
                                        <td>
                                            <Stack>
                                                <Typography>{displayWithNonZeroDecimals(info.data.swapMinus, 1)}</Typography>
                                                <Typography variant="caption" color='divider'>{displayWithNonZeroDecimals(info.data.swapMinusJup, 1)}</Typography>
                                            </Stack>
                                        </td>
                                        <td>
                                            <Stack>
                                                <Typography>
                                                    {info.data.realDepositPercent !== undefined && (
                                                        <>
                                                            {displayWithNonZeroDecimals(info.data.realDepositPercent, 1)} / {displayWithNonZeroDecimals(100 - info.data.realDepositPercent, 1)}
                                                        </>
                                                    )}
                                                    {info.data.realDepositPercent === undefined && info.commandType === 'rebalance' && info.data.settings?.depositPercent !== undefined && (
                                                        <>
                                                            {displayWithNonZeroDecimals(info.data.settings.depositPercent, 1)} / {displayWithNonZeroDecimals(100 - info.data.settings.depositPercent, 1)}
                                                        </>
                                                    )} 
                                                </Typography>
                                                <Typography variant="caption" color='divider'>
                                                    {info.data.depositAmountA !== undefined && (
                                                        <>
                                                            {displayWithNonZeroDecimals(info.data.depositAmountA, 1)} / {displayWithNonZeroDecimals(info.data.depositAmountB, 1)}
                                                        </>
                                                    )}
                                                </Typography>
                                            </Stack>
                                            
                                        </td>
                                        <td>
                                            <Typography color={textColor[info.status]}>
                                                <Stack>
                                                    <Typography>{info.status}</Typography>
                                                    <Typography variant="caption" color='divider'>{info.data.rebalance?.completeReason ?? ''}</Typography>
                                                </Stack>
                                            </Typography>
                                        </td>
                                        <td>
                                            <Box sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}} gap={1}>
                                                <Button color='primary' onClick={()=>handleViewLog(info._id, info.data.positionMint)}>View Log</Button>
                                                {info.status === 'failed' && ctrled && 
                                                <Button color='secondary' onClick={()=>handleAction('redo', info._id)}>Redo</Button>
                                                }
                                            </Box>
                                        </td>
                                    </tr>
                        ))}
                    </tbody>
                </table>
            </Box>
            <Box sx={{
                height: 40,
                bgcolor: '#000',
                display: 'flex',
                alignItems: 'center',
                borderTop: '1px solid #2c2c2c',
                px: 2,
            }}>
                <Typography>Total Earned (USDT): {displayWithNonZeroDecimals(converted, 1)}</Typography>
            </Box>
        </Box>
    );
}